@tailwind base;
@tailwind components;
@tailwind utilities;

.container-box {
  padding-left: 100px;
  padding-right: 100px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
textarea:focus,
input:focus,
select:focus {
  outline: none;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: rgb(34, 197, 94); /* Color of the scrollbar thumb */
  border-radius: 5px; /* Radius of the scrollbar thumb */
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: rgb(
    34,
    197,
    94
  ); /* Color of the scrollbar thumb on hover */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 16px;
}

/* Add this CSS in your stylesheet */
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.page-item {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.page-link {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
}

.page-link:hover {
  background-color: #f2f2f2;
}

.active .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

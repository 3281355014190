.card-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.card-image {
  width: 40%;
  padding-right: 20px;
}

.card-image img {
  width: 100%;
  height: auto;
}

.card-details {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.card-heading {
  margin-bottom: 20px;
}

.card-heading h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card-heading span {
  font-size: 1.2rem;
  font-weight: bold;
}

.card-prices {
  margin-top: 20px;
}

.card-prices h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card-prices ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-prices li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.card-prices li span:first-child {
  font-weight: bold;
  margin-right: 10px;
  width: 300px;
}

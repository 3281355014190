.loader-container {
    position: relative;
    width: 81px;
    height: 80px;
  }
  
  .spinnercard {
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.558);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinners {
    width: 20px;
    height: 20px;
    border: 3px solid #dddddd;
    border-top-color: #423d68;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  